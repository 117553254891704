// Generated by Framer (045d2ed)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["QzpRZ0nff"];

const serializationHash = "framer-1RJ5c"

const variantClassNames = {QzpRZ0nff: "framer-v-11tcjml"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "QzpRZ0nff", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-11tcjml", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"QzpRZ0nff"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QWxleGFuZHJpYS1yZWd1bGFy", "--framer-font-family": "\"Alexandria\", \"Alexandria Placeholder\", sans-serif", "--framer-font-size": "13px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 173, 41))"}}>Home</motion.p></React.Fragment>} className={"framer-jp59b5"} fonts={["GF;Alexandria-regular"]} layoutDependency={layoutDependency} layoutId={"ZYaKkxStC"} style={{"--extracted-r6o4lv": "rgb(255, 173, 41)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1RJ5c.framer-k70tjo, .framer-1RJ5c .framer-k70tjo { display: block; }", ".framer-1RJ5c.framer-11tcjml { height: 16px; overflow: hidden; position: relative; width: 40px; }", ".framer-1RJ5c .framer-jp59b5 { flex: none; height: auto; left: 50%; position: absolute; top: 47%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPt1VEqd0v: React.ComponentType<Props> = withCSS(Component, css, "framer-1RJ5c") as typeof Component;
export default FramerPt1VEqd0v;

FramerPt1VEqd0v.displayName = "Home";

FramerPt1VEqd0v.defaultProps = {height: 16, width: 40};

addFonts(FramerPt1VEqd0v, [{explicitInter: true, fonts: [{family: "Alexandria", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9r7TqbCHJ8BRq0b.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})